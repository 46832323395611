import React from 'react';
import Layout from "../layout";
import styled from 'styled-components';

const WidgetContainer = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 75%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */

    @media only screen and (max-width: 480px){
          padding-top: 200%;
    }

    .responsive-iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
`

const BudMadness = () => {
  return (
    <Layout
        seo={{
          path: "/budmadness",
          title: "Bud Madness",
        }}
    >
        <div className="cContainer">
            <WidgetContainer>
                <iframe title="common ninja madness bracket" className="responsive-iframe" src="https://widgets.commoninja.com/iframe/88f491d1-a4c1-44c4-a5a5-4d0b637c42df"></iframe>
            </WidgetContainer>
        </div>
    </Layout>
  )
}

export default BudMadness